import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { termsAndConditionsUS } from "../utilities/termsAndConditionsUS";

const TermsAndConditionsUS = ({ location }) => {
    return (
        <Layout location={location}>
            {/* Header */}
            <section>
                <div className="max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="pt-32 pb-4 md:pt-40 md:pb-4">
                        {/* Section header */}
                        <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                            <h1 className="h1 mb-4">Service Agreement Terms & Conditions</h1>
                            <h2 className="mb-4 font-semibold">Effective as of November 7, 2023</h2>
                            You can find previous versions here:
                            <span className="text-purple-600 italic"><a href="https://encord.com/terms-and-conditions-us-march-2021/" target="_blank"> March 20, 2021 </a></span>
                            <p className="text-xl text-gray-600 mt-4">
                                Welcome! These user terms of service (the <strong>“Encord Client Terms”</strong> or “<strong>Terms”</strong>) govern your access and use of the Cord Technologies Inc. (<strong>“Encord,”</strong>  <strong>“we,”</strong> or <strong>“us”</strong>) website, software, application, APIs, and any other services provided by us (collectively, the <strong>“Services”</strong>). Please read them carefully.  Website visitors may be referred to in these Terms as <strong>“Site Visitors”</strong> and individuals or entities who use the Services or create an account (<strong>“Account”</strong>) and their Authorized Users are collectively referred to as <strong>“Clients”</strong>. Clients and Site Visitors may be referred to in these Terms as <strong>“users”</strong> <strong>“you”</strong> and <strong>“your”</strong> as applicable.
                                <br /><br />
                                In addition to these terms, we also publish a <a href="https://encord.com/privacy/" target="_blank"><strong>Privacy Policy</strong></a> that describes how we process, collect, use, and protect your information.
                                <br /><br />
                                These Terms are in addition to, and do not nullify, any other agreement between you and Encord or any other applicable terms and conditions provided through the use of the Services. If you are a paid customer (<strong>“Paid Customer”</strong>) and you or your organisation are bound by a separate Services Agreement with Encord, then these Terms will apply, only to the extent that any terms here are not found in your Services Agreement.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/*  Body of text  */}
            <section>
                <div className="max-w-5xl mx-auto px-4 sm:px-6">
                    <div className="pb-12 md:pb-20 privacy-content">
                        <ol className="max-w-3xl mx-auto">
                            {termsAndConditionsUS.map(({ title, points }, index) => (
                                <>
                                    <li className="h3 mb-3" key={index}>
                                        {title}
                                        <ol className="max-w-3xl mx-auto mt-2">
                                            {points.map((data, index) => (
                                                <>
                                                    <li
                                                        key={index}
                                                        className="text-lg font-normal text-gray-600 mb-8"
                                                    >
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: data?.point,
                                                            }}
                                                        />
                                                    </li>
                                                </>
                                            ))}
                                        </ol>
                                    </li>
                                </>
                            ))}
                        </ol>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default TermsAndConditionsUS;

export const Head = () => (
    <SEO
        title="Service Agreement Terms & Conditions US"
        description="Encord Terms of Business US"
    />
);
